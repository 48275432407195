/* Gallery.css */
/* Style for the static iPhone frame border */
.iphone-border {
  width: 375px; /* Adjust the width to match iPhone dimensions */
  height: 667px; /* Adjust the height to match iPhone dimensions */
  margin: 0 auto; /* Center the frame horizontally */
  background-image: url('../images/iphone.png'); /* Update the path to your iPhone image */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden; /* Hide any content that overflows the frame */
  position: relative;
}

.section-text {
  background-color: #0579BC; /* Use the blue background color */
  color: #0FA7FF; /* Apply text color for the section text */
  padding: 16px 0; /* Adjust the padding to center the content vertically */
  text-align: center; /* Center-align the text within the section */
  width: 100px; /* Match the width to iPhone dimensions */
  margin: 0 auto; /* Center the text horizontally within the frame */
}
.section-text h1 {
  color: white; /* Set the text color to white */
}

/* Style for the scrollable content inside the iPhone frame */
.iphone-content {
  max-width: 320px; /* Set a maximum width to ensure it doesn't exceed the iPhone frame */
  width: 100%; /* Make it fluid to fit within the iPhone frame's width */
  height: auto; /* Allow the height to adjust based on content */
  max-height: 587px; /* Set a maximum height to add vertical scrolling */
  overflow-y: auto; /* Add vertical scrollbar when content overflows vertically */
  overflow-x: hidden; /* Hide horizontal scrollbar */
  padding: 20px; /* Add padding to the content inside the iPhone frame */
  box-sizing: border-box; /* Include padding in the width and height calculations */
  margin: 0 auto; /* Center the content horizontally within the iPhone frame */
  top: 40px; /* Adjust the top position as needed */
  bottom: 40px; /* Adjust the bottom position as needed */
}

/* Container for each token data box */
.content--container {
  background-color: #f4f3f9;
  /* Additional styles for your container if needed */
  display: flex;
  flex-wrap: wrap; /* Allow boxes to wrap to the next row on small screens */
  justify-content: center; /* Center-align boxes horizontally */
  
}



.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 36px; /* Add spacing between boxes */
  border: 1px solid #ccc; /* Add a border to separate boxes */
  border-radius: 8px;
  background-color: #ffffff;
  min-height: 500px; /* Fixed height for each box, adjust as needed for mobile size */
  width: 80%; /* Increase the width percentage to make them wider */
  box-sizing: border-box; /* Include padding and border in box size */
  overflow: hidden; /* Hide overflow content */
}

/* Updated CSS for responsive images */
.responsive-image {
  max-width: 100%;
  width: auto; /* Auto-adjust the width */
  height: auto; /* Auto-adjust the height */
  max-height: 100%; /* Set a maximum height for the image */
  border: 1px solid #ccc; /* Add a border around the image */
}



.back-icon {
  transform: scaleX(-1); /* Flip the arrow horizontally */
  margin-right: 8px; /* Adjust the spacing between the arrow and text */
  width: 20px; /* Set the width of the arrow */
  height: 20px; /* Set the height of the arrow */
}

.back-link {
  background-color: #FFFFFF; /* Change the background color to white (#FFFFFF) */
  color: #0FA7FF; /* Change the color to blue or a different color */
  border: none;
  text-decoration: none;
  font-size: 18px;
  cursor: pointer;
}

/* Adjust the button alignment if needed */
.back-button-container {
  display: flex;
  justify-content: flex-start; /* Align the button to the left horizontally */
  align-items: center; /* Center vertically */
  margin: 8px; /* Adjust the margin as needed */
  
}




/* Add other CSS styles as needed */
