/* Footer.css */

.footer {
  background-color: #f5f4f9;
  display: flex;
  justify-content: space-between; /* Spread items evenly along the row */
  align-items: center;
  width: 290px; /* Match the width of the iPhone content */
  margin-left: -10px; /* Add a negative margin to move the footer 20px to the left */
}

.footer-link {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-decoration: none;
  color: #000; /* Adjust text color as needed */
}

.footer-link img {
  max-width: 24px;
  height: auto;
  text-decoration: none;
}

.footer-link a {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center; /* Vertically align text and icons */
  color: #000;
  padding: 10px 0; /* Add vertical padding around the icons */
}

/* Apply a different style when the links are hovered */
.footer-link a:hover {
  /* You can specify hover styles here */
}

/* Add this CSS rule to adjust the size of the icons */
.selected-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #0579BC; /* Fill the circle with #0579BC */
  width: 40px; /* Adjust the width as needed */
  height: 40px; /* Adjust the height as needed */
  border: 2px solid #0579BC; /* Add a circle border with the desired color */
  border-radius: 50%; /* Make the border round to form a circle */
}

.selected-text {
  color: #0FA7FF; /* Apply text color for the text */
}

/* Add styling for the "nft-selected" class */
.nft-selected {
  background-color: #0579BC;
  color: #0FA7FF; /* Apply text color for the "NFTs" text */
}

.unselected-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px; /* Adjust the width as needed */
  height: 40px; /* Adjust the height as needed */
  border-radius: 50%; /* Make the border round to form a circle */
}
