/* Styling for the NFTDetailView component */
/* Style for the static iPhone frame border */
.iphone-border {
  width: 375px; /* Adjust the width to match iPhone dimensions */
  height: 667px; /* Adjust the height to match iPhone dimensions */
  margin: 0 auto; /* Center the frame horizontally */
  background-image: url('../images/iphone.png'); /* Update the path to your iPhone image */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden; /* Hide any content that overflows the frame */
  position: relative;
}

/* Style for the scrollable content inside the iPhone frame */
.iphone-content {
  max-width: 320px; /* Set a maximum width to ensure it doesn't exceed the iPhone frame */
  width: 100%; /* Make it fluid to fit within the iPhone frame's width */
  height: auto; /* Allow the height to adjust based on content */
  max-height: 587px; /* Set a maximum height to add vertical scrolling */
  overflow-y: auto; /* Add vertical scrollbar when content overflows vertically */
  overflow-x: hidden; /* Hide horizontal scrollbar */
  padding: 20px; /* Add padding to the content inside the iPhone frame */
  box-sizing: border-box; /* Include padding in the width and height calculations */
  position: absolute; /* Position the content inside the iPhone frame */
  top: 40px; /* Adjust the top position as needed */
  left: 30px; /* Adjust the left position as needed */
  right: 20px; /* Adjust the right position as needed */
  bottom: 40px; /* Adjust the bottom position as needed */
}

.nft-detail-container {
  background-color: #F4F3F9;
  display: flex;
  flex-wrap: wrap; /* Allow boxes to wrap to the next row on small screens */
  justify-content: center; /* Center-align boxes horizontally */
}

h2 {
  font-size: 24px;
  margin-bottom: 16px;
  color: #333;
}



.section-text {
  background-color: #0579BC; /* Use the blue background color */
  color: #0FA7FF; /* Apply text color for the section text */
  padding: 16px; /* Add padding to adjust the height as needed */
  text-align: center; /* Center-align the text within the section */
  width: 100%; /* Make it span the entire width of the frame */
  margin: 0; /* Remove any margin */
}
.section-text h1 {
  color: white; /* Set the text color to white */
}

.tabs {
  display: flex;
  justify-content: center; /* Center-align the tabs horizontally */
  margin-bottom: 16px;
}

.tab-button {
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  margin-right: 16px;
  background-color: #f0f0f0;
  color: #333;
  transition: background-color 0.3s;
}

.tab-button.active {
  background-color: #333;
  color: #fff;
}

/* Add this style to center-align the "Back" button */
.back-button {
  text-align: left;
  margin: 8px 0; /* Adjust margin as needed */
  padding-left: 16px; /* Add left padding to align it to the left */
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nft-content {
  padding: 8px 16px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nft-image-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 16px;
  overflow: hidden;
  max-width: 100%; /* Make the image responsive */
}

.responsive-image {
  max-width: 100%;
  height: auto;
  display: block; /* Remove extra spacing below the image */
}

.nft-info {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  padding: 16px;
  text-align: left;
  width: 100%; /* Make the content container responsive */
}

.label {
  font-weight: bold;
  margin-bottom: 8px;
}

.value {
  margin-bottom: 16px;
}

.nft-data {
  flex: 1;
  background-color: #fff; /* Set the background color to match the greyish box */
  padding: 16px; /* Add some padding for better readability */
  border-radius: 4px; /* Optionally, add rounded corners for a nicer look */
  word-break: break-word; /* Allow long words to break onto the next line */
  overflow-x: auto; /* Enable horizontal scrolling if content overflows */
  max-width: 100%; /* Ensure the container doesn't exceed its parent's width */
}



.token-data {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  padding: 16px;
  margin-bottom: 16px;
  white-space: pre-wrap;
  margin-left: 20px;
  width: 100%; /* Make the content container responsive */
}

/* Apply these styles to make "View on Block Explorer" a button */
.block-explorer-button {
  margin-bottom: 20px;
  margin-left: 20px;
  display: inline-block;
  padding: 8px 16px;
  background-color: #0579BC; /* Choose a suitable background color */
  color: #fff; /* Text color */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease; /* Add a smooth transition effect */
}

.block-explorer-button:hover {
  background-color: #045a94; /* Change color on hover */
}



.back-link {
  background-color: #FFFFFF; /* Change the background color to white (#FFFFFF) */
  color: #F4F3F9;
  border: none;
  text-decoration: none;
  font-size: 18px; /* Adjust font size as needed */
  display: flex;
  align-items: left;
  cursor: pointer;
}

/* Add additional styles as needed */
